// make this component the parent of all components/ master component
import React,{Component} from 'react';
import CardList from './CardList';
import SearchBox from './SearchBox';
import {robots} from './robots';
import './App.css';

class App extends Component{
    constructor(){
        super()
        this.state = {
            robots: robots,
            searchfield:''
        }
    }
    // event handling in react
    onSearchChange = (event) => {
        // console.log(event.target.value);
        this.setState({searchfield:event.target.value})
    }
    render(){
            const filteredrobots = this.state.robots.filter(robots=>{
                return robots.name.toLowerCase().includes(this.state.searchfield.toLowerCase());
                //return matching robot index from array, convert all to lower case for convenience
            })
            return(
                // this will reflect across all elements
                <div className='tc'>
                <h1>RoboFriends</h1>
                <SearchBox searchChange={this.onSearchChange}/>
                <CardList robots={filteredrobots}/>
            </div>
        );
    }
}

export default App;