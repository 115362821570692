// make this as the parent element of card element
import React from 'react';
import Card from './Card';

const CardList = ({robots}) => {
    
    // const cardArray = robots.map((user,i)=>{
        //     // child component
        //     return <Card key={i} id={robots[i].id} name={robots[i].name} email={robots[i].email}/>
        //     //assign key to make webpage inspect element's warning under console tab go away
        // })
    // alternatively
    return(
        <div>
            {
                robots.map((user,i)=>{
                    return( 
                        <Card 
                            key={i} 
                            id={robots[i].id} 
                            name={robots[i].name} 
                            email={robots[i].email}
                        />
                    );
                })
            }
        </div>
    );
}

export default CardList;