import React from 'react';

const Card = ({id,name,email}) => {
    // destructure, props (placeholder) keyword is no longer required
    // const {id,name,email} = props;
    return(
        <div className='tc bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5'>
            {/* dib stands for display inline block */}
            {/* notice the closing tag shorthand */}
            <img src={`https://robohash.org/${id}?200x200`} alt='robots' /> 
            {/* use concept of template string to pass argument into src attribute */}
            <div>
                <h2>{name}</h2>
                <p>{email}</p>
            </div>
        </div>
    );
}

export default Card;