// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("SEGA.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n    font-family: 'SEGA LOGO FONT';\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    src: local('SEGA LOGO FONT'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\r\n    }\r\n    \r\n    h1 {\r\n      font-family: 'SEGA LOGO FONT';\r\n      font-weight: 200;\r\n      color: #0ccac4;\r\n    }", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;IACnB,oFAA6D;IAC7D;;IAEA;MACE,6BAA6B;MAC7B,gBAAgB;MAChB,cAAc;IAChB","sourcesContent":["@font-face {\r\n    font-family: 'SEGA LOGO FONT';\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    src: local('SEGA LOGO FONT'), url('SEGA.woff') format('woff');\r\n    }\r\n    \r\n    h1 {\r\n      font-family: 'SEGA LOGO FONT';\r\n      font-weight: 200;\r\n      color: #0ccac4;\r\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
